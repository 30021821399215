.w3-theme-l5 {color:#000 !important; background-color:#f6f6f6 !important}
.w3-theme-l4 {color:#000 !important; background-color:#dfdfdf !important}
.w3-theme-l3 {color:#000 !important; background-color:#c0c0c0 !important}
.w3-theme-l2 {color:#fff !important; background-color:#a0a0a0 !important}
.w3-theme-l1 {color:#fff !important; background-color:#818181 !important}
.w3-theme-d1 {color:#fff !important; background-color:#575757 !important}
.w3-theme-d2 {color:#fff !important; background-color:#4e4e4e !important}
.w3-theme-d3 {color:#fff !important; background-color:#444444 !important}
.w3-theme-d4 {color:#ddd !important; background-color:#3a3a3a !important}
.w3-theme-d5 {color:#eee !important; background-color:#303030 !important}
.w3-theme-d6 {color:#eee !important; background-color:#181818 !important}


.w3-theme-l4-hover:hover {color:#000 !important; background-color:#f6f6f6 !important}
.w3-theme-l3-hover:hover {color:#000 !important; background-color:#dfdfdf !important}
.w3-theme-l2-hover:hover {color:#000 !important; background-color:#c0c0c0 !important}
.w3-theme-l1-hover:hover {color:#fff !important; background-color:#a0a0a0 !important}
.w3-theme-d1-hover:hover {color:#fff !important; background-color:#818181 !important}
.w3-theme-d2-hover:hover {color:#fff !important; background-color:#575757 !important}
.w3-theme-d3-hover:hover {color:#fff !important; background-color:#4e4e4e !important}
.w3-theme-d4-hover:hover {color:#fff !important; background-color:#444444 !important}
.w3-theme-d5-hover:hover {color:#ddd !important; background-color:#3a3a3a !important}
.w3-theme-d6-hover:hover {color:#eee !important; background-color:#303030 !important}

.w3-theme-light {color:#000 !important; background-color:#f6f6f6 !important}
.w3-theme-dark {color:#fff !important; background-color:#303030 !important}
.w3-theme-action {color:#fff !important; background-color:#303030 !important}

.w3-theme {color:#fff !important; background-color:#616161 !important}
.w3-text-theme {color:#616161 !important}
.w3-border-theme {border-color:#616161 !important}

.w3-hover-theme:hover {color:#fff !important; background-color:#616161 !important}
.w3-hover-text-theme:hover {color:#616161 !important}
.w3-hover-border-theme:hover {border-color:#616161 !important}

body, html {color:#ddd !important; background-color:#303030 !important}