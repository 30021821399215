body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.w3-round-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.w3-round-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.w3-pointer {
  cursor: pointer;
}

dt {
  font-weight: bold;
}
dd {
  margin-left: 20px;
}

/* TooltipContainer container */
.tooltip,
.tooltip-bottom {
  position: relative;
  display: inline-block;
}

/* TooltipContainer text */
.tooltip .tooltiptext,
.tooltip-bottom .tooltiptext {
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  overflow: visible;

  background-color: #111;
  color: #fff;
  text-align: center;
  padding: 5px 30px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  transition: opacity 0.2s;
}

.tooltip .tooltiptext {
  bottom: calc(100% + 6px);
}
.tooltip-bottom .tooltiptext {
  top: calc(100% + 6px);
}

.tooltip .tooltiptext::after,
.tooltip-bottom .tooltiptext::after {
  content: " ";
  position: absolute;

  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}
.tooltip .tooltiptext::after {
  top: 100%;
  border-color: #111 transparent transparent transparent;
}
.tooltip-bottom .tooltiptext::after {
  bottom: 100%;
  border-color: transparent transparent #111 transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext,
.tooltip-bottom:hover .tooltiptext {
  visibility: visible;
}

input[type="range"].hidden-thumb::-moz-range-thumb {
  opacity: 0;
}
input[type="range"].hidden-thumb::-webkit-slider-thumb {
  opacity: 0;
}
input[type="range"].hidden-thumb::-ms-thumb {
  opacity: 0;
}
