.wrapper {
  box-sizing: border-box;
  position: relative;
  /*position: absolute;*/
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 1em; /* Height of footer */
  /*min-height: 100%;*/
  /*margin-left:10px;*/
}

#toolbar {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(42, 42, 42, 0.8);
  padding: 4px;
  border-radius: 4px;
  z-index: 10;
  margin: 10px;
}

table .header {
  font-weight: bold;
  padding: 10px 5px 10px 5px;
}

table td {
  padding-left: 10px;
  padding-right: 10px;
}

table.no-spacing {
  border-spacing:0; /* Removes the cell spacing via CSS */
  border-collapse: collapse;  /* Optional - if you don't want to have double border where cells touch */
}

.darkmode {
  /*background-color: #121212;*/
  background: rgba(42, 42, 42, 0.8);
  color: #fff;
}

*:disabled {
  opacity: 0.5;
  color: gray;
}

.colorSample {
  width: 50px;
}

#toolbar input[type="checkbox"],
#toolbar select {
  padding: 5px 10px 5px 10px;
  background-color: transparent;
  color: #fff;
}

#legend {
  z-index: 1;
  position: absolute;
  left: 5px;
  top: 150px;
  width: 50px;
}
.legend_color {
  color: white;
  float: top;
  width: 100%;
  margin: 0;
  height: 25px;
  vertical-align: middle;
  color: #000000;
}


/*input[type="range"] {*/
/*  height: 25px;*/
/*  -webkit-appearance: none;*/
/*  margin: 0px 0;*/
/*  width: 90px;*/
/*  background: transparent;*/
/*}*/
/*input[type="range"]:focus {*/
/*  outline: none;*/
/*}*/
/*input[type="range"]::-webkit-slider-runnable-track {*/
/*  width: 100%;*/
/*  height: 5px;*/
/*  cursor: pointer;*/
/*  animate: 0.2s;*/
/*  box-shadow: 0px 0px 0px #000000;*/
/*  background: grey !*#2497e3;*!;*/
/*  border-radius: 1px;*/
/*  border: 0px solid #000000;*/
/*}*/
/*input[type="range"]::-webkit-slider-thumb {*/
/*  box-shadow: 0px 0px 0px #000000;*/
/*  border: 1px solid grey!*#2497e3*!;*/
/*  height: 18px;*/
/*  width: 18px;*/
/*  border-radius: 25px;*/
/*  background: white!*#a1d0ff*!;*/
/*  cursor: pointer;*/
/*  -webkit-appearance: none;*/
/*  margin-top: -7px;*/
/*}*/
/*input[type="range"]:focus::-webkit-slider-runnable-track {*/
/*  background: grey!*#2497e3*!;*/
/*}*/
/*input[type="range"]::-moz-range-track {*/
/*  width: 100%;*/
/*  height: 5px;*/
/*  cursor: pointer;*/
/*  animate: 0.2s;*/
/*  box-shadow: 0px 0px 0px #000000;*/
/*  background: grey!*#2497e3*!;*/
/*  border-radius: 1px;*/
/*  border: 0px solid #000000;*/
/*}*/
/*input[type="range"]::-moz-range-thumb {*/
/*  box-shadow: 0px 0px 0px #000000;*/
/*  border: 1px solid grey!*#2497e3*!;*/
/*  height: 18px;*/
/*  width: 18px;*/
/*  border-radius: 25px;*/
/*  background: white!*#a1d0ff*!;*/
/*  cursor: pointer;*/
/*}*/
/*input[type="range"]::-ms-track {*/
/*  width: 100%;*/
/*  height: 5px;*/
/*  cursor: pointer;*/
/*  animate: 0.2s;*/
/*  background: transparent;*/
/*  border-color: transparent;*/
/*  color: transparent;*/
/*}*/
/*input[type="range"]::-ms-fill-lower {*/
/*  background: grey!*#2497e3*!;*/
/*  border: 0px solid #000000;*/
/*  border-radius: 2px;*/
/*  box-shadow: 0px 0px 0px #000000;*/
/*}*/
/*input[type="range"]::-ms-fill-upper {*/
/*  background: grey!*#2497e3*!;*/
/*  border: 0px solid #000000;*/
/*  border-radius: 2px;*/
/*  box-shadow: 0px 0px 0px #000000;*/
/*}*/
/*input[type="range"]::-ms-thumb {*/
/*  margin-top: 1px;*/
/*  box-shadow: 0px 0px 0px #000000;*/
/*  border: 1px solid grey!*#2497e3*!;*/
/*  height: 18px;*/
/*  width: 18px;*/
/*  border-radius: 25px;*/
/*  background: white!*#a1d0ff*!;*/
/*  cursor: pointer;*/
/*}*/
/*input[type="range"]:focus::-ms-fill-lower {*/
/*  background: grey!*#2497e3*!;*/
/*}*/
/*input[type="range"]:focus::-ms-fill-upper {*/
/*  background: grey!*#2497e3*!;*/
/*}*/


input[type=range] {
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  background: transparent;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.1s;
  background: #2497e3;
  /*border-radius: 25px;*/
}
input[type=range]::-webkit-slider-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0,0,0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #2497e3;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.1s;
  background: #2497e3;
}
input[type=range]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 #000000;
  /*border: 1px solid grey!*#2497e3*!;*/
  background: white/*#a1d0ff*/;
  cursor: pointer;
}


.range-wrap{
  width: 100%;/*500px;*/
  position: relative;
}
.range-value{
  position: absolute;
  top: -100%;
}
.range-value span{
  width: 24px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background: #2497e3;
  color: #fff;
  font-size: 10px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
}
.range-value span:before{
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #2497e3;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}


input[type="checkbox"] {
  accent-color: #87CEEB;
}

.flipswitch {
  position: relative;
  /*background: white;*/
  width: 60px;
  height: 25px;
  -webkit-appearance: initial;
  border-radius: 3px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  font-size: 10px;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid #aaa;
}

.flipswitch:after {
  position: absolute;
  top: 5%;
  display: block;
  line-height: 20px;
  width: 45%;
  height: 90%;
  background: #aaa;
  box-sizing: border-box;
  text-align: center;
  transition: all 0.2s ease-in 0s;
  color: black;
  border: #888 1px solid;
  border-radius: 3px;
}

.flipswitch:after {
  left: 2%;
  /*content: "OFF";*/
  content: attr(flip-state-off);
  background: red;
  color: #fff;
}

.flipswitch:checked:after {
  left: 53%;
  /*content: "ON";*/
  content: attr(flip-state-on);
  background: greenyellow;
  color: #000;
}

.cesiumViewer > select {
  width: 100%;
  /* styling */
  background-color: white;
  border: thin solid white;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  /*padding: 0.5em 3.5em 0.5em 1em;*/
  padding-left: 0;
  padding-right: 0;
  text-align: center;

  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cesiumViewer > select option {
  background-color: black;
  color: #fff;
  text-shadow: 0 1px 0 black;

  /* reset */
  -webkit-appearance: none;
  appearance: none;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fa_rotate_120 {
  --fa-rotate-angle: 120deg;
}

.fa_rotate_240 {
  --fa-rotate-angle: 240deg;
}

.cesium-infoBox {
  position: absolute;
  left: 30%;
}

.alert-show-temp {
  opacity: 1;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  transition: all 1000ms linear 2s;
}

.spinner {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.5), -1.8em -1.8em 0 0em rgba(255,255,255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.5), 1.8em -1.8em 0 0em rgba(255,255,255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.5), 2.5em 0em 0 0em rgba(255,255,255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.5), 1.75em 1.75em 0 0em rgba(255,255,255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.5), 0em 2.5em 0 0em rgba(255,255,255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.5), -1.8em 1.8em 0 0em rgba(255,255,255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.5), -2.6em 0em 0 0em rgba(255,255,255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.round-top-left {
  border-radius: 5px 0 0 0
}

.round-top-right {
  border-radius: 0 5px 0 0
}

.bold {
  font-weight: bold;
}

.svg-light-blue,.svg-hover-light-blue:hover {
  filter: invert(89%) sepia(20%) saturate(5369%) hue-rotate(173deg) brightness(108%) contrast(84%);
}

.svg-amber,.svg-hover-amber:hover {
  filter: invert(70%) sepia(58%) saturate(794%) hue-rotate(354deg) brightness(105%) contrast(101%);
}

.svg-white,.svg-hover-white:hover {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(279deg) brightness(103%) contrast(102%);
}

.svg-rotate-90 { transform: rotate(90deg); }
.svg-rotate-180 { transform: rotate(180deg); }
.svg-rotate-270 { transform: rotate(270deg); }

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container > div {
  margin: 5px;
}

.text-full-justify {
  text-align: justify;
  text-justify: inter-word;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: matrix(1.5, 0, 0, 1.5, 0, 2.5)
}

.zoom-left {
  transition: transform .1s;
}

.zoom-left:hover {
  /*matrix(scaleX(), skewY(), skewX(), scaleY(), translateX(), translateY())*/
  transform: matrix(3, 0, 0, 3, -55, 0)
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  80% { transform: rotate(0deg); }
  85% { transform: rotate(10deg); }
  95% { transform: rotate(-10deg); }
  100% { transform: rotate(0deg); }
}

div.wiggle {
  display: inline-block;
  animation: wiggle 1.5s infinite;
}

@keyframes pulse {
  0% { transform: matrix(1, 0, 0, 1, 0, 0) ; }
  80% { transform: matrix(1, 0, 0, 1, 0, 0) ; }
  90% { transform: matrix(1.5, 0, 0, 1.5, 0, 2.5) ; }
  100% { transform: matrix(1, 0, 0, 1, 0, 0) ; }
}

div.pulse {
  display: inline-block;
  animation: pulse 2.5s infinite;
}

/*div.wiggle:hover {*/
/*  animation: none;*/
/*}*/

.circle-border {
  border-radius: 50%;
}

.hidden-scrollbars::-webkit-scrollbar { display: none; }
.hidden-scrollbars {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.text-bold {
  font-weight: bold;
}
