.container {
    display: flex;

    /* Misc */
    border: 1px solid #cbd5e0;
    height: 25vh;
    width: 100%;
}
.resizer_horizontal {
    background-color: #cbd5e0;
    cursor: ew-resize;
    height: 100%;
    width: 2px;
}
.resizer_vertical {
    background-color: #cbd5e0;
    cursor: ns-resize;
    height: 2px;
    width: 100%;
}
.container__left {
    /* Initially, the left takes 1/4 width */
    width: 25%;

    /* Misc */
    align-items: center;
    display: flex;
    justify-content: center;
}
.container__right {
    /* Take the remaining width */
    flex: 1;

    /* Misc */
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.vcontainer {
    /*height:100%;*/
    width:100%;
    /* Misc */
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.container__top {
    /* Initial height */
    /*height: 100%;*/
    /*flex: 1;*/
    width: 100%;
    /* Misc */
    align-items: center;
    display: flex;
    justify-content: center;
}
.container__bottom {
    /* Take the remaining height */
    /*flex: 1;*/

    /*height: 100%;*/
    width: 100%;
    /* Misc */
    align-items: center;
    display: flex;
    justify-content: center;
}

.container__middle {
    /* Take the remaining height */
    /*flex: 1;*/

    /*height: 100%;*/
    width: 100%;

    /* Misc */
    align-items: center;
    display: flex;
    justify-content: center;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}